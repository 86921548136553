<template>
  <div class="profile-block">
    <div class="form-select-block">
      <div class="form-select-block-title">Выберите период:</div>
      <div class="form-select-block-select">
        <select v-if="months" @change="getData($event.target.value)">
          <option v-for="(month, index) in months" :value="index" :key="index">
            {{ format(month, 'LLLL yyyy', { locale: ru }) }}
          </option>
        </select>
      </div>
    </div>

    <div class="history-table-wrap">
      <div class="history-table2" v-if="!this.isLoading && this.list">
        <div class="tr1">
          <div class="td1">№</div>
          <div class="td2">Дата</div>
          <div class="td3">Тип операции</div>
          <div class="td4">Сумма</div>
          <div class="td5">Кошелек</div>
          <div class="td6">Описание</div>
        </div>

        <div class="tr2" v-for="(elem, index) in list" :key="index">
          <div class="td1">{{ index }}</div>
          <div class="td2">{{ formatInTimeZone(new Date(elem?.Date), 'GMT', 'dd.MM.yyyy HH:mm') }}</div>

          <template v-if="elem.withdraw > 0">
            <div class="td3">Снятие</div>
            <div class="td4">
              <div class="pay-out-line">{{ n(elem?.withdraw) }} ₽</div>
            </div>
          </template>
          <template v-else>
            <div class="td5">Пополнение</div>
            <div class="td6">
              <div class="pay-in-line">{{ n(elem?.deposit) }} ₽</div>
            </div>
          </template>

          <div class="td7">{{ t('payments.' + elem?.purse, elem?.purse) }}</div>
          <div class="td8">{{ translateDesc(elem?.desc) }}</div>
        </div>
      </div>
      <div v-else-if="this.isLoading && !this.list">Загрузка</div>
      <div v-else>Данных нет</div>
    </div>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n'
import { format, subMonths } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { ru } from 'date-fns/esm/locale'
import { GET_PAYMENTS_HISTORY } from '@/store/actions.type'
import { toast } from 'vue3-toastify'

export default {
  name: 'Payments',
  setup() {
    const { t, n, d } = useI18n()
    return { t, n, d }
  },
  data() {
    return {
      page: 0,
      tab: 0,
      months: null,
      list: null,
      isLoading: true,
      format,
      formatInTimeZone,
      ru,
    }
  },
  mounted() {
    let array = []

    for (let i = 0; i <= 7; i++) {
      array.push(subMonths(new Date(), i))
    }

    this.months = array

    this.getData(0)
  },
  methods: {
    getData(value) {
      this.$store
        .dispatch(GET_PAYMENTS_HISTORY, {
          time_zone_shift: new Date().getTimezoneOffset() / 60,
          month_shift: Number(value),
        })
        .then(({ data }) => {
          this.list = data
          this.isLoading = false
        })
        .catch(res => {
          toast.error(res?.data?.err)
          this.isLoading = false
        })
    },
    translateDesc(desc) {
      const parts = []
      desc.split(' ').forEach(el => {
        if (el.startsWith('out')) {
          parts.push(el.substring(3))
        } else {
          parts.push(this.t(`payments.${el}`, el))
        }
      })
      return parts.join(' ')
    },
  },
}
</script>

<style scoped></style>
